import {
  ButtonV3 as Button,
  CheckBox,
  InputFieldV3 as Input,
  SelectorV3 as Selector,
  TextButton,
  theme,
} from '@provi/provi-components'
import { ChangeEvent, Dispatch, memo, useCallback, useEffect, useMemo } from 'react'
import { Alert } from '~/components/atoms/Alert'
import PencilIcon from '~/components/atoms/PencilIcon'
import { LoadingInput } from '~/components/molecules'
import { masks } from '~/enums/masks'
import { ITemporaryCreditCardData } from '~/types/index'
import { addMask, useWindowSize } from '~/utils/index'
import { IPartnerConditions, useCreditCardForm } from './hooks'
import * as S from './style'

interface ICreditCardForm {
  temporaryCreditCardData: ITemporaryCreditCardData | null
  setTemporaryCreditCardData: Dispatch<ITemporaryCreditCardData | null>
  imTheCardHolder: boolean
  setImTheCardHolder: Dispatch<boolean>
  hideSubmit?: boolean
  setIsValid?: Dispatch<boolean>
}

const CreditCardForm = ({
  temporaryCreditCardData,
  setTemporaryCreditCardData,
  imTheCardHolder,
  setImTheCardHolder,
  hideSubmit,
  setIsValid,
}: ICreditCardForm) => {
  const {
    isCreditCardLoading,
    chosenPaymentMethod,
    partnerConditions,
    handlePartnerConditions,
    handleCardHolder,
    cardHolderData,
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleSubmit,
    handleEnterKey,
    validateForm,
    setFieldTouched,
    getObjectFromPartnerConditionsId,
    setTextFieldData,
    isSendingData,
    hasAnyPartnerConditionWithInterest,
    inPageError,
    installmentRestrictionReason,
    selectedOverTwelveInstallments,
  } = useCreditCardForm({
    setTemporaryCreditCardData,
    temporaryCreditCardData,
    imTheCardHolder,
    setImTheCardHolder,
    hideSubmit,
  })

  useEffect(() => {
    setIsValid?.(isValid)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  const { width } = useWindowSize()

  const CreditCardHolderInformation = useCallback(({ field, value }) => {
    return (
      <S.CreditCardHolderContainer>
        <S.CreditCardHolderField>{`${field}: `}</S.CreditCardHolderField>
        <S.CreditCardHolderValue as="span" data-recording-sensitive>
          {value}
        </S.CreditCardHolderValue>
      </S.CreditCardHolderContainer>
    )
  }, [])

  const renderCardCountrySelector = useMemo(() => {
    return (
      <S.SelectorWrapper>
        <S.LabelSelector>Tipo de cartão</S.LabelSelector>
        <S.SelectContent
          id="chosen-card-country"
          placeholder="Selecione o tipo de cartão"
          value={values.cardCountry}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setTextFieldData('cardCountry', event.target.value)
          }}
          onBlur={() => {
            setFieldTouched('cardCountry')
            validateForm()
          }}
        >
          <option value="" hidden>
            Selecionar tipo de cartão
          </option>
          <option value="br">🇧🇷 Emitido no Brasil</option>
          <option value="intl">🌍 Emitido fora do Brasil</option>
        </S.SelectContent>
      </S.SelectorWrapper>
    )
  }, [values.cardCountry, setTextFieldData, setFieldTouched, validateForm])

  const renderSelectorByScreenSize = useMemo(() => {
    if ((width && width < 800) || hideSubmit) {
      return (
        <S.SelectorWrapper>
          <S.LabelSelector>Número de parcelas</S.LabelSelector>
          <S.SelectContent
            id="chosen-partner-condition"
            placeholder="Selecione o número de parcelas"
            value={values.partnerConditionId}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              handlePartnerConditions(event)
            }}
            onBlur={() => {
              setFieldTouched('partnerConditionId')
              validateForm()
            }}
          >
            <option value="" hidden>
              Selecionar parcela
            </option>
            {partnerConditions.map((condition: IPartnerConditions) => (
              <option key={condition.value} value={condition.value}>
                {condition.labelInMobile}
              </option>
            ))}
          </S.SelectContent>
          {hasAnyPartnerConditionWithInterest ? <sub>* o valor parcelado possui acréscimo</sub> : null}
        </S.SelectorWrapper>
      )
    }

    return (
      <>
        <Selector
          label="Número de parcelas"
          instanceId="chosen-partner-condition"
          id="chosen-partner-condition"
          inputId="chosen-partner-condition"
          isSearchable={false}
          isLoading={isCreditCardLoading}
          onChange={handlePartnerConditions}
          options={partnerConditions}
          value={getObjectFromPartnerConditionsId}
          placeholder="Selecione o número de parcelas"
          width="100%"
        />
        {hasAnyPartnerConditionWithInterest ? <sub>* o valor parcelado possui acréscimo</sub> : null}
      </>
    )
  }, [
    width,
    isCreditCardLoading,
    handlePartnerConditions,
    partnerConditions,
    getObjectFromPartnerConditionsId,
    values,
    setFieldTouched,
    validateForm,
    hideSubmit,
    hasAnyPartnerConditionWithInterest,
  ])

  const renderCreditCardLoading = useMemo(() => {
    return (
      <S.CreditCardFormWrapper isVariant={hideSubmit}>
        <LoadingInput />
        <LoadingInput />
        <S.CreditCardSmallInputs isVariant={hideSubmit}>
          <LoadingInput />
          <LoadingInput />
        </S.CreditCardSmallInputs>
        <LoadingInput />

        <S.CreditCardHolderData shouldBeAbleToEditInputs={false}>
          <S.CreditCardLoadingTitle />
          <>
            <S.CreditCardHolderInformationContainer>
              <S.CreditCardLoadingText />
              <S.CreditCardLoadingText />
              <S.CreditCardLoadingText />
            </S.CreditCardHolderInformationContainer>

            <S.CreditCardLoadingButton />
          </>
        </S.CreditCardHolderData>
      </S.CreditCardFormWrapper>
    )
  }, [hideSubmit])

  const renderHolderInformation = useMemo(() => {
    return (
      <>
        <CheckBox
          text="Não sou o títular do cartão"
          checked={!imTheCardHolder}
          selectedColor={theme.colors.bluePrimary}
          onChange={() => {
            setImTheCardHolder(!imTheCardHolder)
          }}
        />
        {!imTheCardHolder && (
          <>
            <Input
              inputMode="numeric"
              pattern="[0-9]*"
              type="text"
              label="CPF"
              placeholder="816.799.670-02"
              name="holderCPF"
              width="100%"
              value={values.holderCPF || temporaryCreditCardData?.holderCPF}
              mask={masks.cpf}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setTextFieldData('holderCPF', e.target.value)
              }}
              onBlur={() => {
                setFieldTouched('holderCPF')
                validateForm()
              }}
              isValid={!errors.holderCPF && touched.holderCPF}
              hasError={errors.holderCPF && touched.holderCPF}
              errorMessage={errors.holderCPF}
              onKeyDown={handleEnterKey}
            />

            <Input
              label="Nome do titular"
              placeholder="Cecília Meireles"
              name="cardName"
              width="100%"
              value={values.cardName || temporaryCreditCardData?.cardName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setTextFieldData('cardName', e.target.value)
              }}
              onBlur={() => {
                setFieldTouched('cardName')
                validateForm()
              }}
              isValid={!errors.cardName && touched.cardName}
              hasError={errors.cardName && touched.cardName}
              errorMessage={errors.cardName}
              onKeyDown={handleEnterKey}
            />
          </>
        )}
      </>
    )
  }, [
    temporaryCreditCardData,
    values,
    errors,
    touched,
    setFieldTouched,
    validateForm,
    setTextFieldData,
    handleEnterKey,
    imTheCardHolder,
    setImTheCardHolder,
  ])

  if (isCreditCardLoading && process.env.NODE_ENV !== 'test') {
    return renderCreditCardLoading
  }

  return (
    <>
      <S.CreditCardFormWrapper isVariant={hideSubmit}>
        {!hideSubmit && renderSelectorByScreenSize}
        {renderCardCountrySelector}
        <Input
          inputMode="numeric"
          pattern="[0-9]*"
          type="text"
          label="Número do cartão"
          placeholder="0000 0000 0000 0000"
          name="cardNumber"
          width="100%"
          value={values.cardNumber || temporaryCreditCardData?.cardNumber}
          mask={masks.creditCard}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setTextFieldData('cardNumber', e.target.value)
          }}
          onBlur={() => {
            setFieldTouched('cardNumber')
            validateForm()
          }}
          isValid={!errors.cardNumber && touched.cardNumber}
          hasError={errors.cardNumber && touched.cardNumber}
          errorMessage={errors.cardNumber}
          onKeyDown={handleEnterKey}
        />
        <S.CreditCardSmallInputs isVariant={hideSubmit}>
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            label="Validade"
            placeholder="12/22"
            name="cardExpireDate"
            width="160px"
            value={values.cardExpireDate || temporaryCreditCardData?.cardExpireDate}
            mask={masks.expireDate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setTextFieldData('cardExpireDate', e.target.value)
            }}
            onBlur={() => {
              setFieldTouched('cardExpireDate')
              validateForm()
            }}
            isValid={!errors.cardExpireDate && touched.cardExpireDate}
            hasError={errors.cardExpireDate && touched.cardExpireDate}
            errorMessage={errors.cardExpireDate}
            onKeyDown={handleEnterKey}
          />

          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            label="Código de segurança"
            placeholder="123"
            name="cardCvv"
            width="184px"
            value={values.cardCvv || temporaryCreditCardData?.cardCvv}
            mask={masks.cardCVV}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setTextFieldData('cardCvv', e.target.value)
            }}
            onBlur={() => {
              setFieldTouched('cardCvv')
              validateForm()
            }}
            isValid={!errors.cardCvv && touched.cardCvv}
            hasError={errors.cardCvv && touched.cardCvv}
            errorMessage={errors.cardCvv}
            onKeyDown={handleEnterKey}
          />
        </S.CreditCardSmallInputs>
        {!hideSubmit && (
          <Input
            label="Nome do titular"
            placeholder="Cecília Meireles"
            name="cardName"
            width="100%"
            value={values.cardName || temporaryCreditCardData?.cardName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setTextFieldData('cardName', e.target.value)
            }}
            onBlur={() => {
              setFieldTouched('cardName')
              validateForm()
            }}
            isValid={!errors.cardName && touched.cardName}
            hasError={errors.cardName && touched.cardName}
            errorMessage={errors.cardName}
            onKeyDown={handleEnterKey}
          />
        )}
        {hideSubmit && renderHolderInformation}
        {hideSubmit && renderSelectorByScreenSize}

        {!hideSubmit && (
          <S.CreditCardHolderData shouldBeAbleToEditInputs={!imTheCardHolder}>
            <S.CreditCardHolderTitle as="h2">Dados do titular do cartão</S.CreditCardHolderTitle>
            {imTheCardHolder ? (
              <>
                <S.CreditCardHolderInformationContainer>
                  <CreditCardHolderInformation
                    field="CPF"
                    value={cardHolderData?.CPF ? addMask(cardHolderData?.CPF || '', '###.###.###-##') : ''}
                  />
                  <CreditCardHolderInformation
                    field="Data de nascimento"
                    value={cardHolderData.birthDate ? cardHolderData?.birthDate : ''}
                  />
                  <CreditCardHolderInformation
                    field="Celular"
                    value={cardHolderData.phone ? addMask(cardHolderData?.phone, '(##) #####-####') : ''}
                  />
                </S.CreditCardHolderInformationContainer>

                <TextButton
                  onClick={handleCardHolder}
                  text={
                    <>
                      Editar <PencilIcon />
                    </>
                  }
                />
              </>
            ) : (
              <S.CreditCardHolderInformationInputs>
                <Input
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="text"
                  label="CPF"
                  placeholder="816.799.670-02"
                  name="holderCPF"
                  width="100%"
                  value={values.holderCPF || temporaryCreditCardData?.holderCPF}
                  mask={masks.cpf}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setTextFieldData('holderCPF', e.target.value)
                  }}
                  onBlur={() => {
                    setFieldTouched('holderCPF')
                    validateForm()
                  }}
                  isValid={!errors.holderCPF && touched.holderCPF}
                  hasError={errors.holderCPF && touched.holderCPF}
                  errorMessage={errors.holderCPF}
                  onKeyDown={handleEnterKey}
                />
                <Input
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="text"
                  label="Data de nascimento"
                  placeholder="31/10/1980"
                  name="birthDate"
                  width="100%"
                  value={values.birthDate || temporaryCreditCardData?.birthDate}
                  mask={masks.birthDate}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setTextFieldData('birthDate', e.target.value)
                  }}
                  onBlur={() => {
                    setFieldTouched('birthDate')
                    validateForm()
                  }}
                  isValid={!errors.birthDate && touched.birthDate}
                  hasError={errors.birthDate && touched.birthDate}
                  errorMessage={errors.birthDate}
                  onKeyDown={handleEnterKey}
                />
                <Input
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="text"
                  label="Celular"
                  placeholder="(11) 91444-7176"
                  name="phone"
                  width="100%"
                  value={values.phone || temporaryCreditCardData?.phone}
                  mask={masks.phone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setTextFieldData('phone', e.target.value)
                  }}
                  onBlur={() => {
                    setFieldTouched('phone')
                    validateForm()
                  }}
                  isValid={!errors.phone && touched.phone}
                  hasError={errors.phone && touched.phone}
                  errorMessage={errors.phone}
                  onKeyDown={handleEnterKey}
                />
              </S.CreditCardHolderInformationInputs>
            )}
          </S.CreditCardHolderData>
        )}
      </S.CreditCardFormWrapper>

      {inPageError?.errorResume && <Alert status="warning">{inPageError.errorResume}</Alert>}

      {(installmentRestrictionReason || values.cardCountry === 'intl') && (
        <Alert status="info">
          {installmentRestrictionReason || 'Cartões emitidos fora do Brasil não permitem parcelamento.'}
        </Alert>
      )}

      {selectedOverTwelveInstallments && (
        <Alert status="info">
          {`Parcelamentos acima de 12x estão sujeitos a aprovação do emissor do cartão, mas fique tranquilo, te manteremos
          informado :).`}
        </Alert>
      )}

      {!hideSubmit && (
        <S.ButtonWrapper shouldShowOnMobile={!!chosenPaymentMethod?.length}>
          <Button
            text="Continuar"
            type="submit"
            icon={true}
            mobileWidth="100%"
            disabled={!(isValid && dirty)}
            onClick={handleSubmit}
            isLoading={isSendingData}
          />
        </S.ButtonWrapper>
      )}
    </>
  )
}

export default memo(CreditCardForm)
